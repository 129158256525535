import axios from "axios";

const api = axios.create({
  baseURL: "https://api.coastdeploy.com/",
  headers: {
    "X-Api-Key": "U6SdU3j5.mXgpFw3gQhavC2DAosMGGWFrKgf4p1CT",
  },
});

export async function paymentDetails(payload) {
  const API_PAYMENT = "api/v1/payment/payment/";
  try {
    const res = await api.post(API_PAYMENT, payload).catch(function (error) {
      return error;
    });
    return res;
  } catch (error) {
    return error;
  }
}
export async function deviceDetails(payload) {
  const API_DEVICE_DETAILS = "api/v1/payment/device/data/";
  try {
    const response = await api
      .post(API_DEVICE_DETAILS, payload)
      .catch(function (errors) {
        return errors;
      });
    return response;
  } catch (errors) {
    return errors;
  }
}
