import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import {
  browserName,
  browserVersion,
  deviceType,
  engineName,
  engineVersion,
  fullBrowserVersion,
  getUA,
  mobileModel,
  osName,
  osVersion,
  mobileVendor,
} from "react-device-detect";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { CircularProgress, Box, IconButton } from "@material-ui/core";
import { paymentDetails, deviceDetails } from "../../api";
import camelcaseKeys from "camelcase-keys";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  payNowButton: {
    padding: "10px",
    width: "100%",
  },
  inputTypographyHeading: {
    fontSize: "18px",
    fontWeight: "900",
  },
  mainGrid: {
    height: "90vh",
  },
  inputTypographyDetails: {
    textAlign: "right",
    paddingTop: "1%",
    fontSize: "18px",
  },
  inputTypographyTitle: {
    paddingTop: "1%",
    fontSize: "18px",
  },
  inputTypographyTotal: {
    paddingTop: "4%",
    fontSize: "18px",
    fontWeight: "900",
    paddingBottom: "4%",
  },
  total: { borderTop: "1px solid" },
  progress: {
    top: "20%",
    left: 0,
    right: 0,
  },
  box: {
    "min-height": "100vh",
  },
}));
const CollectJsComponent = () => {
  const classes = useStyles();
  const [radioButtonValue, setRadioButtonValue] = React.useState("1");
  const [state, setState] = useState(false);
  const [enableDeviceData, setenableDeviceData] = useState(true);

  const finishSubmit = async (response) => {
    let res;
    if (response) {
      if (response.token) {
        setState(true);
        res = await paymentDetails({
          ..._data,
          payment_token: response.token,
          card: response.card,
          method: parseInt(radioButtonValue),
        });
        setState(false);
      }
    }
    const newRes = camelcaseKeys(res, { deep: true, pascalCase: true });
    window.parent.postMessage(
      {
        message: res.message ? "Failed" : "Successful",
        response: JSON.parse(JSON.stringify(newRes)),
      },
      data.host // add parent's url
    );
  };

  const onSubmit = () => {
    setState(true);
    window.CollectJS.startPaymentRequest();

    setTimeout(() => {
      setState(false);
    }, 1800);
  };

  const handleClose = () => {
    window.parent.postMessage(
      {
        message: "close iframe",
      },
      data.host
    );
    setRadioButtonValue(null);
  };

  const dispatchDeviceDetails = async () => {
    const request_data = {
      deviceData: {
        browserName,
        browserVersion,
        deviceType,
        engineName,
        engineVersion,
        fullBrowserVersion,
        getUA,
        mobileModel,
        osName,
        osVersion,
        mobileVendor,
        iframeContentLoadedSuccessfully: true,
      },
      hostData: _data,
    };
    await deviceDetails({ request_data });
  };

  useEffect(() => {
    if (enableDeviceData) {
      dispatchDeviceDetails();
    }
    setenableDeviceData(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableDeviceData]);

  let params = window.location.href.split("?")[1].split("&");

  const data = {};
  for (let x in params) {
    data[params[x].split("=")[0]] = params[x].split("=")[1];
  }
  let _data = data;
  const isProduction = _data?.production;
  if (isProduction === "true") {
    _data = { ..._data, production: true };
    window.CollectJS.config.tokenizationKey = "ZF6f2a-RSbSyb-n47tFm-YxVK4U";
  } else {
    _data = { ..._data, production: false };
    window.CollectJS.config.tokenizationKey = "bRkpF2-SRxPuY-QrXv73-J9FgNB";
  }
  const handleRadioButtonChange = (event) => {
    event.preventDefault();
    if (event.target.value === "1") {
      window.CollectJS.config.paymentType = "cc";
    } else {
      window.CollectJS.config.paymentType = "ck";
    }
    setRadioButtonValue(event.target.value);
  };
  const amount = _data.amount;
  const convenienceFee =
    radioButtonValue === "1"
      ? ((parseFloat(amount) * 3.99) / 100).toFixed(2)
      : "5.00";
  const total = (parseFloat(amount) + parseFloat(convenienceFee)).toFixed(2);
  useEffect(() => {
    window.CollectJS.configure({
      variant: "lightbox",
      callback: (token) => {
        finishSubmit(token);
      },
    });
  });
  return (
    <>
      {state ? (
        <Box
          className={classes.box}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          m="2rem">
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Grid>
              <Grid container direction="column">
                <Typography className={classes.inputTypographyHeading}>
                  Please select the right payment method before proceeding.
                </Typography>
                <Grid item>
                  <RadioGroup
                    value={radioButtonValue}
                    onChange={handleRadioButtonChange}>
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Pay via Credit Card"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Pay via E-Check (ACH)"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid>
                <Typography
                  className={classes.inputTypographyHeading}
                  style={{ color: "red" }}>
                  All payments incur a convenience fee.
                </Typography>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.inputTypographyTitle}>
                    Product Type
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.inputTypographyDetails}>
                    {_data.product_type}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.inputTypographyTitle}>
                    Payment
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.inputTypographyDetails}>
                    {amount}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.inputTypographyTitle}>
                    Convenience Fee
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.inputTypographyDetails}>
                    {convenienceFee}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.total}>
                <Grid item xs={6}>
                  <Typography className={classes.inputTypographyTotal}>
                    Total
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={classes.inputTypographyTotal}
                    style={{ textAlign: "right" }}>
                    ${total}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!radioButtonValue}
                  className={classes.payNowButton}
                  onClick={onSubmit}>
                  Pay Now
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CollectJsComponent;
